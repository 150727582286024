<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Signer des contrats de droits français</h2>
		</div>

		<h2>
			Principe de base</h2>
		<p>

			Un investisseur veut obtenir un contrat de droit français pour investir dans de l'immobilier fractionné.

		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Rada va investir 1000 euros dans du Berlcoin sur le marché primaire et signer un contrat de droit français.
			<br />
		</p>

		<h3>
			Sur le site berlcoin.berlin</h3>
		<p>
		</p>
		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/signberlcoin-1.png" />
				<br /> <br /> Rada se rend sur le site <a href="https://berlcoin.berlin/Investir"> berlcoin.berlin</a> sur la page Investir, saisie sa clé publique, et le montant de son investissement
			</div><br />
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/signberlcoin-2.png" />
				<br /> <br /> Rada choisit un contrat a terme de 3 ans, et saisie son année de naissance.
				<br />
				<br />
			</div>

			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/signberlcoin-3.png" />
				<br /> <br />
				Le contrat est généré, puis envoyé à Rada
				<br /><br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/signberlcoin-4.png" /><br />
				<br />
				Rada reçoit son contrat par mail et le lien pour la signature dans la blockchain.
				<br />
			</div>

			<h3 class="my-12">
				Signer le contrat dans la blockchain</h3>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/sign-6.png" /><br />
				<br /> <br />
				<b> Rada clique sur le lien reçu par mail, le wallet affiche avec le lien vers le contrat et le code de signature qui sera intégré dans la blockchain. Rada saisie son code et valide.
				</b> <br />
			</div>





		</div>



		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Rada a payé 1 XLM (ici à 0.20 euro ) pour l'intégration.

			</p>
		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
